 <template>
  <div>
    <CModal color="danger" :show="deleteModal">
      <template v-slot:header>
        <h5 class="modal-title">Delete Product Category</h5>
        <button
          type="button"
          aria-label="Close"
          class="close text-white"
          @click="closeModal(deleteModal)"
        >
          ×
        </button>
      </template>
      Do you want to delete "<b>{{ product_category ? product_category.name : null}}</b>"?
      <template v-slot:footer>
        <CButton color="secondary" @click="closeModal(deleteModal)"
          >Cancel</CButton
        >

        <CButton color="secondary" class="px-4" disabled v-if="isSaving">
          <CSpinner grow size="sm" /> Deleting..
        </CButton>
        <CButton color="danger" @click="deleteProductCategory(product_category)" v-else>
          Delete Product Category
        </CButton>
      </template>
    </CModal>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "ProductCategoryDelete",
  computed: {
    ...mapState({
      deleteModal: (state) => state.product_categories.deleteModal,
      product_category: (state) => state.product_categories.deleteProductCategory,
      isSaving: (state) => state.product_categories.isSaving,
    }),
  },
  methods: {
    closeModal(bool) {
      this.$store.commit("product_categories/DELETE_MODAL", { bool: bool, product_category: null });
    },
    deleteProductCategory(product_category) {
      this.$store.dispatch("product_categories/destroyProductCategory", { 
        product_category: product_category
      });
    },
  },
};
</script>